<template>
  <div class="footer">
    <a
      href="https://github.com/felipe0328"
      class="btn p-1"
      target="_blank"
      rel="noreferrer noopener"
    >
      <fa icon="fa-brands fa-square-github" class="footer-icon" />
    </a>

    <a
      href="https://www.linkedin.com/in/ofguino/"
      class="btn p-1"
      target="_blank"
      rel="noreferrer noopener"
    >
      <fa icon="fa-brands fa-linkedin" class="footer-icon" />
    </a>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.footer {
  position: absolute;
  bottom: 0;
  right: 0;
}

.footer-icon {
  /* position: relative; */
  font-size: 3vmax;
}
</style>
